import React from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { createGlobalStyle } from '@xstyled/styled-components'

import 'bootstrap/scss/bootstrap.scss'

import configureStore from '../model/store'

import App from '../components/App.js'

const NEXT_QUESTION_DELAY = 250

const GlobalStyle = createGlobalStyle`
  html, body, #___gatsby {
    display: flex;
    min-height: 100vh;
    width: 100%;
  }
  #___gatsby > div {
    display: flex;
    min-height: 100vh;
    width: 100%;
  }
`

const IndexPage = () => {
  const store = configureStore()
  return [
    <GlobalStyle key="global" />,
    <Helmet key="helmet">
      <title>Daily Questions</title>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"
      />
      <meta name="apple-mobile-web-app-capable" content="yes" />
    </Helmet>,
    <Provider key="container" store={store}>
      <App />
    </Provider>,
  ]
}
export default IndexPage
