import Airtable from 'airtable'
import { uniqBy as _uniqBy, sortBy as _sortBy } from 'lodash'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { all, call, put, takeLatest } from 'redux-saga/effects'

const FETCH_QUESTIONS = 'FETCH_QUESTIONS'
const REPLACE_QUESTIONS = 'REPLACE_QUESTIONS'
const QUESTIONS_FAILED = 'QUESTIONS_FAILED'
const QUESTIONS_SUCCESS = 'QUESTIONS_SUCCESS'

export const Actions = {
  FETCH_QUESTIONS,
}

export const Shape = PropTypes.shape({
  isActive: PropTypes.bool,
  order: PropTypes.number,
  text: PropTypes.string,
})

const base = new Airtable({ apiKey: 'keynNAlECNAhP7j71' }).base('appGeMf68LhzvEIbK')

export class Question {
  static fetchAll() {
    return new Promise((resolve, reject) => {
      const questions = []
      base('Questions')
        .select({
          filterByFormula: '{Active}',
          view: 'Grid view',
        })
        .eachPage(
          (records, fetchNextPage) => {
            records.forEach((r, i) =>
              questions.push({
                ...r.fields,
                id: r.id,
                order: i,
              })
            )
            fetchNextPage()
          },
          err => {
            if (err) reject(err)
            else resolve(questions)
          }
        )
    })
  }
}

function* getQuestions({}) {
  try {
    const questions = yield call(Question.fetchAll, {})
    yield put({ questions, type: REPLACE_QUESTIONS })
    yield put({ type: QUESTIONS_SUCCESS })
  } catch (e) {
    yield put({ message: e.message, type: QUESTIONS_FAILED })
  }
}

export function* QuestionsSaga() {
  yield all([takeLatest(FETCH_QUESTIONS, getQuestions)])
}

const sortedQuestions = questions => _sortBy(_uniqBy(questions, 'id'), 'order')

export const QuestionsReducer = ({ questions = [] } = {}) => (
  state = { questions, isLoading: false },
  action
) => {
  switch (action.type) {
    case QUESTIONS_FAILED:
      toast.error(action.message)
      console.error(action)
    // eslint-disable-next-line no-fallthrough
    case QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case FETCH_QUESTIONS:
      return {
        ...state,
        isLoading: true,
      }

    case REPLACE_QUESTIONS:
      return {
        ...state,
        isLoading: false,
        questions: sortedQuestions(action.questions),
      }

    default:
      return state
  }
}
