import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faBackspace } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'
import React from 'react'
import styled from '@xstyled/styled-components'

import { Button, Container, Row } from 'reactstrap'

const NumberButton = styled(Button)`
  flex-basis: 33%;
  font-size: 3rem !important;
  line-height: 1.5 !important;
  padding: 0 !important;
  transition-property: color;
  transition-duration: 50ms;
  transition-timing-function: ease-in;
  &:focus,
  &:hover {
    /* color: #007bff !important; */
    outline: none !important;
    text-decoration: none !important;
  }
`

const NumberKey = ({ callback, i, ...props }) => (
  <NumberButton color="link" onClick={() => callback(i)} {...props}>
    {i}
  </NumberButton>
)

const NumberInput = ({ backCallback, callback, number, setNumber }) => {
  const handleBackspace = () => {
    if (number !== null) setNumber(null)
    else backCallback()
  }
  const handleNextPressed = () => {
    callback(number)
  }
  const handleNumberPressed = i => {
    if (i === 1 && number === null) {
      setNumber(1)
    } else {
      let nextNumber = number
      if (i === 0 && number === 1) nextNumber = 10
      else if (number === null) nextNumber = i
      setNumber(nextNumber)
      callback(nextNumber)
    }
  }
  const numberKeysDisabled = number !== null && number !== 1
  return (
    <Container>
      <Row className="justify-content-center">
        <NumberKey callback={handleNumberPressed} disabled={numberKeysDisabled} i={1} />
        <NumberKey callback={handleNumberPressed} disabled={numberKeysDisabled} i={2} />
        <NumberKey callback={handleNumberPressed} disabled={numberKeysDisabled} i={3} />
      </Row>
      <Row className="justify-content-center">
        <NumberKey callback={handleNumberPressed} disabled={numberKeysDisabled} i={4} />
        <NumberKey callback={handleNumberPressed} disabled={numberKeysDisabled} i={5} />
        <NumberKey callback={handleNumberPressed} disabled={numberKeysDisabled} i={6} />
      </Row>
      <Row className="justify-content-center">
        <NumberKey callback={handleNumberPressed} disabled={numberKeysDisabled} i={7} />
        <NumberKey callback={handleNumberPressed} disabled={numberKeysDisabled} i={8} />
        <NumberKey callback={handleNumberPressed} disabled={numberKeysDisabled} i={9} />
      </Row>
      <Row className="justify-content- mb-5">
        <NumberButton color="link" onClick={handleBackspace}>
          <FontAwesomeIcon icon={number === null ? faArrowLeft : faBackspace} />
        </NumberButton>
        <NumberKey callback={handleNumberPressed} disabled={numberKeysDisabled} i={0} />
        {number !== null && (
          <NumberButton color="link" onClick={handleNextPressed}>
            <FontAwesomeIcon icon={faArrowRight} />
          </NumberButton>
        )}
      </Row>
    </Container>
  )
}
NumberInput.propTypes = {
  backCallback: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
  number: PropTypes.number,
  setNumber: PropTypes.func.isRequired,
}

export default NumberInput
