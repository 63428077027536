import Airtable from 'airtable'
import { sortBy as _sortBy, uniqBy as _uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { all, call, put, takeLatest } from 'redux-saga/effects'

const FETCH_ANSWERS = 'FETCH_ANSWERS'
const INSERT_ANSWER = 'INSERT_ANSWER'
const REPLACE_ANSWERS = 'REPLACE_ANSWERS'
const SAVE_ANSWER = 'SAVE_ANSWER'
const UPSERT_ANSWER = 'UPSERT_ANSWER'
const ANSWERS_FAILED = 'ANSWERS_FAILED'
const ANSWERS_SUCCESS = 'ANSWERS_SUCCESS'

export const Actions = {
  FETCH_ANSWERS,
  SAVE_ANSWER,
}

export const Shape = PropTypes.shape({
  isActive: PropTypes.bool,
  order: PropTypes.number,
  text: PropTypes.string,
})

const base = new Airtable({ apiKey: 'keynNAlECNAhP7j71' }).base('appGeMf68LhzvEIbK')

export class Answer {
  static fetchAll() {
    return new Promise((resolve, reject) => {
      const answers = []
      base('Answers')
        .select({
          filterByFormula: "NOT({Date} = '')",
        })
        .eachPage(
          (records, fetchNextPage) => {
            records.forEach((r, i) =>
              answers.push({
                ...r.fields,
                id: r.id,
              })
            )
            fetchNextPage()
          },
          err => {
            if (err) reject(err)
            else resolve(answers)
          }
        )
    })
  }
  static save({ id, ...answer }) {
    return new Promise((resolve, reject) => {
      if (Boolean(id)) {
        base('Answers').update(id, answer, (err, record) => {
          if (err) {
            console.error(err)
            return reject(err)
          }
          resolve({ ...answer, ...record.fields, id: record.id })
        })
      } else {
        base('Answers').create(answer, (err, record) => {
          if (err) {
            console.error(err)
            return reject(err)
          }
          resolve({
            ...answer,
            id: record.getId(),
          })
        })
      }
    })
  }
}

function* getAnswers({}) {
  try {
    const answers = yield call(Answer.fetchAll, {})
    yield put({ answers, type: REPLACE_ANSWERS })
    yield put({ type: ANSWERS_SUCCESS })
  } catch (e) {
    yield put({ message: e.message, type: ANSWERS_FAILED })
  }
}

function* saveAnswer({ answer: a }) {
  try {
    const answer = yield call(Answer.save, a)
    if (Boolean(a.id)) yield put({ answer, type: UPSERT_ANSWER })
    else yield put({ answer, type: INSERT_ANSWER })
    yield put({ type: ANSWERS_SUCCESS })
  } catch (e) {
    yield put({ message: e.message, type: ANSWERS_FAILED })
  }
}

export function* AnswersSaga() {
  yield all([takeLatest(FETCH_ANSWERS, getAnswers), takeLatest(SAVE_ANSWER, saveAnswer)])
}

const sortedAnswers = answers => _sortBy(_uniqBy(answers, 'id'), 'Date')

export const AnswersReducer = ({ answers = [] } = {}) => (
  state = { answers, isLoading: false },
  action
) => {
  switch (action.type) {
    case ANSWERS_FAILED:
      toast.error(action.message)
      console.error(action)
    // eslint-disable-next-line no-fallthrough
    case ANSWERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }
    case FETCH_ANSWERS:
      return {
        ...state,
        isLoading: true,
      }
    case INSERT_ANSWER:
      return {
        ...state,
        answers: sortedAnswers([...state.answers, action.answer]),
      }
    case REPLACE_ANSWERS:
      return {
        ...state,
        answers: sortedAnswers(action.answers),
        isLoading: false,
      }
    case SAVE_ANSWER:
      return {
        ...state,
        isLoading: true,
      }
    case UPSERT_ANSWER:
      return {
        ...state,
        answers: sortedAnswers(
          state.answers.map(a =>
            a.id !== action.answer.id && a.Date !== action.answer.Date
              ? a
              : { ...a, ...action.answer }
          )
        ),
      }
    default:
      return state
  }
}
