import Airtable from 'airtable'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension'

import { all, takeLatest } from 'redux-saga/effects'

import { AnswersReducer, AnswersSaga } from './Answer'
import { QuestionsReducer, QuestionsSaga } from './Question'

function* RootSaga() {
  yield all([AnswersSaga(), QuestionsSaga()])
}
const rootReducer = () =>
  combineReducers({
    answers: AnswersReducer(),
    questions: QuestionsReducer(),
  })

const sagaMiddleware = createSagaMiddleware()

const logger = store => next => action => {
  console.group(action.type)
  console.info('dispatching', action)
  // eslint-disable-next-line callback-return
  let result = next(action)
  console.log('next state', store.getState())
  console.groupEnd()
  return result
}

export default () => {
  const store = createStore(
    rootReducer(),
    {},
    process.env.NODE_ENV === 'development'
      ? composeWithDevTools(applyMiddleware(logger, sagaMiddleware))
      : applyMiddleware(sagaMiddleware)
  )
  sagaMiddleware.run(RootSaga)
  return store
}
