import { takeRight as _takeRight } from 'lodash'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import DatePicker from 'react-datepicker'
import Draggable from 'react-draggable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import { round as _round } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import useInterval from 'react-useinterval'
import { Sparklines, SparklinesLine } from 'react-sparklines'
import styled, { createGlobalStyle } from '@xstyled/styled-components'

import { Col, Container, Progress, Row } from 'reactstrap'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'

import { Actions as AnswerActions } from '../model/Answer'
import { Actions as QuestionActions } from '../model/Question'
import NumberInput from './NumberInput.js'

const NEXT_QUESTION_DELAY = 250

const GlobalStyle = createGlobalStyle`
html, body {
  display: block;
  height: 100%;
  overflow: hidden;
  overscroll-behavior: none;
  position: fixed;
  width: 100%;
}
`

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
  margin-bottom: 15px;
  margin-top: 15px;
  text-align: center;
  .react-datepicker-wrapper {
    display: flex;
    .react-datepicker__input-container {
      display: flex;
      width: 100%;
      input {
        border: 0;
        outline: none;
        text-align: center;
        width: 100%;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        border-radius: 0;
        -moz-border-radius: 0;
        -webkit-border-radius: 0;
      }
    }
  }
`

const Subtitle = styled.h3`
  color: var(--grey);
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
  margin-top: 2rem;
  text-align: center;
`

const Question = styled.h2`
  color: ${({ willTrigger }) => (willTrigger ? 'var(--primary)' : 'inherit')};
  text-align: center;
`

const BigNumber = styled.h1`
  font-size: 8rem;
  font-weight: 300;
  line-height: 1.2;
  margin-top: 2rem;
  text-align: center;
`

const StyledProgress = styled(Progress)`
  border-radius: 0 !important;
  height: 2px !important;
  font-size: 2px !important;
  margin-left: -15px;
  margin-right: -15px;
  .progress-bar {
    height: 2px;
    transition: none;
  }
`

const Countdown = ({ completeCallback, interval }) => {
  const msPerTick = 16
  const tickSize = parseInt(100 / (interval / msPerTick))
  const [value, setValue] = useState(100)
  useInterval(() => {
    const nextValue = parseInt(value - tickSize)
    if (nextValue <= 0 && value !== 0) {
      setValue(0)
    } else setValue(nextValue)
  }, msPerTick)
  useEffect(() => {
    if (value === 0) completeCallback()
  }, [value])
  return <StyledProgress value={value} />
}

const LoadingView = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 100%;
  h1 {
    svg {
      display: block;
      font-size: 5rem;
      margin-bottom: 1rem;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const valueForQuestion = (answer, question) => {
  var value = null
  const text = (question.Question || '').toLowerCase()
  Object.entries(answer || {}).forEach(([key, v]) => {
    if (key.toLowerCase() === text) value = v
  })
  return value
}

const localDate = d =>
  new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().substring(0, 10)

const App = () => {
  const dispatch = useDispatch()
  const fetchQuestions = () => dispatch({ type: QuestionActions.FETCH_QUESTIONS })
  const fetchAnswers = () => dispatch({ type: AnswerActions.FETCH_ANSWERS })
  const saveAnswer = answer => dispatch({ answer, type: AnswerActions.SAVE_ANSWER })
  const { answers, answersLoading } = useSelector(
    ({ answers: { answers, isLoading: answersLoading } }) => ({ answers, answersLoading })
  )
  const { questions, questionsLoading } = useSelector(
    ({ questions: { isLoading: questionsLoading, questions } }) => ({ questions, questionsLoading })
  )
  const [dateString, setDateString] = useState(localDate(new Date()))
  const [idx, setIdx] = useState(0)
  const [number, setNumber] = useState(null)
  const [nextQuestion, setNextQuestion] = useState(null)
  const [answer, setAnswer] = useState({})
  const [trend, setTrend] = useState([])
  const travelTrigger = 65
  const [dragStart, setDragStart] = useState(0)
  const [travelProgress, setTravelProgress] = useState(0)
  const [willTrigger, setWillTrigger] = useState(false)
  const [x, setX] = useState(0)
  const travel = y => Math.abs(Math.abs(dragStart) - Math.abs(y))
  const countdownCallback = () => {
    setIdx(nextQuestion)
    setNextQuestion(null)
  }
  const question = questions[idx] || {}
  const text = (question.Question || '').toLowerCase()
  useEffect(() => {
    // Fetch data from API on load
    fetchQuestions()
    fetchAnswers()
  }, [])
  useEffect(() => {
    // Find answer for the current date
    const answer = answers.find(a => a.Date === dateString)
    if (!answer && !answersLoading && answers.length) {
      saveAnswer({ Date: dateString })
    } else if (answer) {
      setAnswer(answer)
      // Advance question to first without an answer
      questions.some((q, i) => {
        const value = valueForQuestion(answer, q)
        if (value === null) {
          setIdx(i)
          return true
        }
      })
    }
  }, [answers, answersLoading, dateString])
  useEffect(() => {
    // Build data set for trend (for sparkline TBD)
    const trend = []
    answers.forEach(answer => {
      const value = valueForQuestion(answer, question)
      trend.push({ date: answer.Date, value })
    })
    setTrend(_takeRight(trend.map(t => (t.value === null ? 0 : t.value)), 10))
  }, [answers, idx])
  useEffect(() => {
    // Find answer for current question
    setNumber(valueForQuestion(answer, question))
  }, [answer, idx])
  useEffect(() => {
    // Advance to next question
    setNextQuestion(null)
    if (idx >= questions.length || idx < 0) setIdx(0)
  }, [idx])
  if (questionsLoading)
    return (
      <LoadingView>
        <h1>
          <FontAwesomeIcon icon={faSync} spin />
          Loading...
        </h1>
      </LoadingView>
    )
  const backCallback = () => {
    const next = idx - 1
    if (next >= 0) setIdx(next)
  }
  const callback = number => {
    const next = idx + 1
    if (next < questions.length - 1) {
      setNextQuestion(next)
    }
    saveAnswer({
      id: answer.id,
      [question.Question]: number,
    })
  }
  const yearProgress =
    (Math.floor((Date.now() - Date.parse(new Date().getFullYear(), 0, 0)) / 86400000) /
      (new Date().getFullYear() % 4 == 0 ? 366 : 365)) *
    100
  const progress = ((idx + 1) / questions.length) * 100
  const onDrag = (_, { x }) => {
    setX(x)
    const travelled = travel(x)
    const travelProgress = _round(Math.min(1, travelled / travelTrigger), 2)
    setTravelProgress(travelProgress)
    setWillTrigger(travelled >= travelTrigger)
  }
  const onStart = (_, { x }) => {
    setDragStart(Math.abs(x))
    setWillTrigger(false)
  }
  const onStop = () => {
    const travelled = travel(x)
    const didTrigger = travelled >= travelTrigger
    const nextIdx = x > 0 ? Math.max(0, idx - 1) : Math.min(idx + 1, questions.length - 1)
    setTravelProgress(0)
    setWillTrigger(false)
    setX(0)
    if (didTrigger) {
      setIdx(nextIdx)
    }
  }
  return (
    <StyledContainer>
      <GlobalStyle />
      <Row className="mb-auto">
        <Col>
          <StyledProgress color="secondary" value={yearProgress} />
          <Title>
            <DatePicker
              selected={moment(dateString).toDate()}
              onChange={d => setDateString(localDate(d))}
            />
          </Title>
          <StyledProgress value={progress} />
          <Subtitle>Did you try your best to</Subtitle>
          <Draggable
            axis="x"
            defaultPosition={{ x: 0, y: 0 }}
            position={{ x, y: 0 }}
            onStart={onStart}
            onDrag={onDrag}
            onStop={onStop}
          >
            <Question willTrigger={willTrigger}>{text}</Question>
          </Draggable>
          <Sparklines data={trend} height={20}>
            <SparklinesLine color="blue" />
          </Sparklines>
          <BigNumber>
            {number}
            {!Boolean(answer.id) && <FontAwesomeIcon icon={faSync} spin />}
          </BigNumber>
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberInput
            backCallback={backCallback}
            callback={callback}
            number={number}
            setNumber={setNumber}
          />
        </Col>
      </Row>
      {nextQuestion !== null && (
        <Countdown completeCallback={countdownCallback} interval={NEXT_QUESTION_DELAY} />
      )}
      <ToastContainer autoClose={5000} />
    </StyledContainer>
  )
}

export default App
